<template>
  <div class="flex-start">
    <a-card style="width: 60%;margin-right: 15px;">
      <div class="flex-start margin-bottom">
        <img :src="userInfo.headImage" style="width: 60px;height: 60px;margin-right: 15px;" alt="">
        <div>{{ userInfo.name }}<br>
          <span class="text" v-if="userInfo.sex === 1">男</span>
          <span class="text" v-else>女</span>
          <br>{{ userInfo.phone }}
        </div>
      </div>
      <div class="flex-start margin-bottom">
        <span class="label">生日</span>
        <span class="text">{{ userInfo.birthday }}</span>
      </div>
      <div class="flex-start margin-bottom">
        <span class="label">兴趣</span>
        <span class="text">{{ this.$utils.getstr(userInfo.userTabs) }}</span>
      </div>
      <div class="flex-start margin-bottom">
        <span class="label">数据</span>
        <span class="text"> 喜欢 {{ userInfo.like }} 收藏 {{ userInfo.favorite }} 关注 {{ userInfo.follow }}</span>
      </div>
      <div class="flex-start">
        <span class="label">最后IP属地</span>
        <span class="text">{{ userInfo.ipLocation }}</span>
      </div>
    </a-card>
    <a-card style="flex: 1;">
      <p style="font-weight: bold;font-size: 20px;">账户信息</p>
      <div class="flex-start margin-bottom">
        <span class="label">状态</span>
        <span class="text" v-if="userInfo.state === 1">正常</span>
        <span class="text" v-else>禁用</span>
      </div>
      <div class="flex-start margin-bottom">
        <span class="label">实名认证</span>
        <span>{{ statusRealAry[userInfo.real] }}</span>
      </div>
      <div class="flex-start margin-bottom">
        <span class="label">最后登录时间</span>
        <span class="text">{{ userInfo.lastLoginTime }}</span>
      </div>
      <div class="flex-start">
        <span class="label">注册时间</span>
        <span class="text">{{ userInfo.createdTime }}</span>
      </div>
    </a-card>
  </div>
</template>

<script>
  export default {
    name: 'UserTop',
    props: {
      userInfo: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        statusRealAry: ['未提交实名认证', '已实名认证', '实名认证待审核'],
      }
    },
    created() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .label {
    margin-right: 4px;
    display: inline-block;
    width: 100px;
  }
</style>
