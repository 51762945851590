<template>
  <div class="container">
    <!--账户信息-->
    <UserTop :userInfo="userInfo" />
    <a-form-model ref="form" :rules="rules" style="width: 500px;margin-top: 20px;" :model="formData" :colon="false">
      <a-form-model-item label="入驻类型" prop="businessTag">
        <a-radio-group v-model="formData.businessTag" :defaultValue="1" disabled>
          <a-radio :value="2">企业</a-radio>
          <a-radio :value="1">个人</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input allowClear v-model="formData.shopName" placeholder="请输入" disabled />
      </a-form-model-item>
      <a-form-model-item label="企业名称" prop="companyName" v-if="formData.businessTag === 2">
        <a-input allowClear v-model="formData.companyName" placeholder="请输入" disabled />
      </a-form-model-item>
      <a-form-model-item label="入驻平台" prop="businessType">
        <a-select allowClear v-model="formData.businessType" placeholder="请选择" disabled>
          <a-select-option :value="1">创作者平台</a-select-option>
          <a-select-option :value="2">独立店</a-select-option>
          <a-select-option :value="3">商家</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="applyState">
        <a-radio-group v-model="formData.applyState" :defaultValue="1" disabled>
          <a-radio :value="0">审核中</a-radio>
          <a-radio :value="1">已通过</a-radio>
          <a-radio :value="2">未通过</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import UserTop from '@/components/UserTop'

  import * as Settle from '@/api/user/settle'

  export default {
    components: {
      UserTop
    },
    name: 'insert',
    data() {
      return {
        userInfo: {},
        formData: {},
        rules: {
          businessTag: [{
            required: true,
            message: '请选择入驻类型',
            trigger: 'change'
          }],
          shopName: [{
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur'
          }],
          companyName: [{
            required: true,
            message: '请输入企业名称',
            trigger: 'blur'
          }],
          businessClass: [{
            required: true,
            message: '请选择经营类型',
            trigger: 'change'
          }],
          businessType: [{
            required: true,
            message: '请选择入驻平台',
            trigger: 'change'
          }],
          applyState: [{
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }]
        }
      }
    },
    created() {
      this.userId = this.$route.query.id;
      this.querySettleById()
    },
    methods: {
      querySettleById() {
        Settle.querySettleById(this.userId).then(res => {
          const data = res.data || {}
          this.formData = data || {}
          this.userInfo = data.personalResp || {}
        }).finally(() => {
          this.loading = false
        })
      },
      submit() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    padding: 20px;
    background: #ffffff;

    .label {
      width: 100px;
    }
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  /deep/.ant-form-item-required {
    font-size: 18px;
    font-weight: bold;
  }
</style>
